import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BannerIMG } from '~/assets/img';
import {
  Button,
  DescList,
  Description,
  FloatDiv,
  Image,
  Paragraph,
  Section,
} from './styles';

const Banner: FC<any> = ({ toForm, ...rest }) => {
  const { t } = useTranslation('translation');

  return (
    <Section {...rest}>
      <FloatDiv>
        <Paragraph>{t('techHealth')}</Paragraph>
        <Description> {t('driveDesc')}</Description>
        <DescList>
          <li>{t('driveOpt1')}</li>
          <li>{t('driveOpt2')}</li>
          <li>{t('driveOpt3')}</li>
          <li>{t('driveOpt4')}</li>
          <li>{t('driveOpt5')}</li>
        </DescList>
        <Button onClick={toForm}>{t('submitBanner')}</Button>
      </FloatDiv>
      <Image src={BannerIMG} />
    </Section>
  );
};

export default Banner;
