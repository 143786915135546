import React, { FC, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import {
  CloseIcon,
  InstaLogo,
  LinkedInLogo,
  Logo,
  Menu as MenuIcon,
} from '~/assets/img';
import { device } from '~/theme/breakpoints';
import { ScrollLink } from '~/utils';
import If from '../If';
import {
  AlignItemsMenu,
  ButtonContact,
  ButtonPortal,
  ItemMenu,
  LogoIMG,
  MenuIMG,
  OptionsMenu,
  Section,
  SectionSocial,
  SocialLogo,
  Touchable,
} from './styles';

const Header: FC<any> = ({ toHome, toForm }) => {
  const { t } = useTranslation('translation');
  const [menu, setMenu] = useState(true);

  const onInsta = () => {
    window.open('https://www.instagram.com/transceptar', '_blank');
    setMenu(!menu);
  };

  const onLinkedIn = () => {
    window.open(
      'https://www.linkedin.com/in/transceptar-s-a-58b48924a',
      '_blank',
    );
    setMenu(!menu);
  };

  const onContact = () => {
    toForm();
    setMenu(!menu);
  };

  const onCloseMenu = () => {
    toHome();
    setMenu(true);
  };

  return (
    <>
      <If condition={device.isMobile}>
        <Section>
          <LogoIMG src={Logo} />
          <div style={{ alignItems: 'center', width: '20%' }}>
            {/* <Touchable onClick={onInsta}>
              <SocialLogo src={InstaLogo} />
            </Touchable>

            <Touchable onClick={onLinkedIn}>
              <SocialLogo src={LinkedInLogo} />
            </Touchable> */}
          </div>
          <MenuIMG src={MenuIcon} onClick={() => setMenu(!menu)} />
        </Section>

        <If condition={!menu}>
          <Menu
            disableOverlayClick
            disableAutoFocus
            disableCloseOnEsc
            isOpen
            styles={{
              bmMenuWrap: {
                background: '#040404',
                width: '100%',
              },
              bmMenu: { width: '100%' },
            }}
          >
            <Touchable
              onClick={() => setMenu(!menu)}
              style={{
                display: 'flex',
                height: 80,
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                marginLeft: 10,
              }}
            >
              <MenuIMG src={CloseIcon} onClick={() => setMenu(false)} />
            </Touchable>

            <ScrollLink to="sectionAbout" onClick={onCloseMenu}>
              <ItemMenu>{t('about')}</ItemMenu>
            </ScrollLink>

            <Link to="sectionTechnology" spy smooth onClick={onCloseMenu}>
              <ItemMenu>{t('technology')}</ItemMenu>
            </Link>

            <ScrollLink to="sectionSolutions" onClick={onCloseMenu}>
              <ItemMenu>{t('solutions')}</ItemMenu>
            </ScrollLink>

            <ScrollLink to="sectionInTheNews" onClick={onCloseMenu}>
              <ItemMenu>{t('InTheNews')}</ItemMenu>
            </ScrollLink>

            <SectionSocial>
              {/* <Touchable onClick={onInsta}>
                <SocialLogo src={InstaLogo} />
              </Touchable>

              <Touchable onClick={onLinkedIn}>
                <SocialLogo src={LinkedInLogo} />
              </Touchable> */}
            </SectionSocial>

            <ButtonContact onClick={onContact}>{t('contactUs')}</ButtonContact>
          </Menu>
        </If>
      </If>

      <If condition={!device.isMobile}>
        <Section>
          <AlignItemsMenu>
            <LogoIMG src={Logo} />

            <OptionsMenu>
              <ScrollLink to="sectionAbout" onClick={toHome}>
                <ItemMenu>{t('about')}</ItemMenu>
              </ScrollLink>

              <ScrollLink to="sectionTechnology" onClick={toHome}>
                <ItemMenu>{t('technology')}</ItemMenu>
              </ScrollLink>

              <ScrollLink to="sectionSolutions" onClick={toHome}>
                <ItemMenu>{t('solutions')}</ItemMenu>
              </ScrollLink>

              <ScrollLink to="sectionInTheNews" onClick={toHome}>
                <ItemMenu>{t('InTheNews')}</ItemMenu>
              </ScrollLink>

              <SectionSocial>
                {/* <Touchable onClick={onInsta}>
                  <SocialLogo src={InstaLogo} />
                </Touchable>

                <Touchable onClick={onLinkedIn}>
                  <SocialLogo src={LinkedInLogo} />
                </Touchable> */}
              </SectionSocial>
            </OptionsMenu>
            <ButtonPortal href="https://transceptar.online/" target="_blank">
              {t('portalUs')}
            </ButtonPortal>
            <ButtonContact onClick={onContact}>{t('contactUs')}</ButtonContact>
          </AlignItemsMenu>
        </Section>
      </If>
    </>
  );
};

export default Header;
