import styled from 'styled-components';
import bk from '~/theme/breakpoints';
import { pxToRem } from '~/utils';

export const Section = styled.div`
  margin-top: 10vh;
  max-height: 650px;

  @media ${bk.inMobile} {
    max-height: unset;
    min-height: 400px;
  }
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;

  @media ${bk.inMobile} {
    width: 100vw;
    /* height: ${pxToRem(300)}; */
    object-position: 0;
  }
`;

export const FloatDiv = styled.div`
  z-index: 1;
  position: absolute;
  flex-direction: column;
  top: 20vh;
  left: 7vw;

  @media ${bk.inMobile} {
    top: ${pxToRem(100)};
    left: ${pxToRem(15)};
  }
`;

export const Paragraph = styled.p`
  font-size: 1.7vw;
  font-weight: 500;
  color: #656565;

  @media ${bk.inMobile} {
    font-size: 1rem;
  }
`;

export const Description = styled.p`
  font-size: 2vw;
  margin: 0;
  font-weight: bold;
  background: linear-gradient(95.26deg, #057cd7 7.4%, #00dfff 105.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media ${bk.inMobile} {
    width: 60%;
    font-size: 1.5rem;
  }
`;

export const DescList = styled.ul`
  margin: 30px 0;
  padding-left: 24px;

  @media ${bk.inMobile} {
    font-size: 0.8rem;
  }
`;

export const Button = styled.button`
  border: 0px;
  height: ${pxToRem(40)};
  width: ${pxToRem(150)};
  border-radius: 8px;
  background-color: #101010;
  color: #fafafa;
  margin-top: 20px;

  @media ${bk.inMobile} {
    margin-top: unset;
  }
`;
