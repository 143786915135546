import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BannerTechnologies, Pin } from '~/assets/img';
import {
  Description,
  Image,
  Paragraph,
  PinImg,
  RightDescription,
  RightParagraph,
  RightTitle,
  Section,
  SectionRight,
  SectionText,
  TextWrapper,
} from './styles';

type Props = {};

const Technologies: FC<Props> = () => {
  const { t } = useTranslation('translation');

  return (
    <Section id="sectionAbout">
      <Image src={BannerTechnologies} />

      <SectionText>
        <PinImg src={Pin} />

        <TextWrapper>
          <Paragraph>{t('kfar')}</Paragraph>
          <Description>
            {t('kfarDescSubOne')}
            <br />
            {t('kfarDescOne')}
            <br /> {t('kfarDescTwo')}
            <br /> {t('kfarDescThree')}
            <br /> {t('kfarDescFour')}
            <br /> {t('kfarDescFive')}
          </Description>
        </TextWrapper>
      </SectionText>

      <SectionRight>
        <RightTitle>{t('transTech')}</RightTitle>
        <RightParagraph>{t('transTechDescOne')}</RightParagraph>
        <RightDescription>{t('transTechDescTwo')}</RightDescription>
        <RightDescription>{t('transTechDescThree')}</RightDescription>
      </SectionRight>
    </Section>
  );
};

export default Technologies;
