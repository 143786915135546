import styled from 'styled-components';
import bk from '~/theme/breakpoints';
import { pxToRem } from '~/utils';

export const Section = styled.div`
  flex-direction: column;

  @media ${bk.inMobile} {
    background-color: #000000;
  }
`;

export const ImageBanner = styled.img`
  width: 100%;
  object-fit: cover;
  display: none;

  @media ${bk.inDesktop} {
    display: block;
  }
`;

export const SectionText = styled.div`
  width: 100%;
  position: absolute;
  flex-direction: row;
  left: 8vw;
  margin-top: 8vh;

  @media ${bk.inMobile} {
    position: initial;
    background-color: #000000;
    min-height: auto;
    left: 0;
    margin-top: 0;
  }
`;

export const TextWrapper = styled.div`
  width: 45%;
  flex-direction: column;
  margin-left: 20px;

  @media ${bk.inMobile} {
    width: 90%;
    margin: 0 auto;
    padding: 15px 0;
  }
`;

export const Title = styled.p`
  font-size: 1.8vw;
  margin-bottom: 10px;
  font-weight: 500;
  color: #656565;

  @media ${bk.inMobile} {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #fff;
    text-align: center;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.9vw;
  margin-bottom: 10px;
  font-weight: bold;
  color: #3d3d3d;

  @media ${bk.inMobile} {
    margin-top: 0px;
    font-size: 0.9rem;
    color: #fff;
    text-align: center;
  }
`;

export const Description = styled.p`
  font-size: 1.4vw;
  font-weight: 300;
  color: #444444;

  @media ${bk.inMobile} {
    font-size: 0.9rem;
    color: #fff;
    text-align: center;
  }
`;

export const FooterDescription = styled.div`
  flex-direction: column;
  width: 100%;
  min-height: ${pxToRem(250)};
  background-color: #f7f7f7;
  align-items: center;
  justify-content: center;
`;

export const FooterText = styled.p`
  width: 60%;
  text-align: center;
  font-size: 2.2vw;
  font-weight: bold;
  color: #0090ff;

  @media ${bk.inMobile} {
    width: 80%;
    margin-top: 0px;
    font-size: 1rem;
  }
`;

export const Button = styled.button`
  border: 0px;
  height: ${pxToRem(40)};
  width: ${pxToRem(150)};
  border-radius: 8px;
  background-color: #101010;
  color: #fafafa;
  cursor: pointer;
`;
