/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ElementIMG, PlusIMG, TargetIMG } from '~/assets/img';
import {
  Description,
  Footer,
  IconIMG,
  Paragraph,
  Section,
  SectionText,
  Wrapper,
} from './styles';

type Props = {};

const Benefits: FC<Props> = () => {
  const { t } = useTranslation('translation');

  return (
    <Wrapper>
      <Section>
        <SectionText>
          <IconIMG src={TargetIMG} />
          <Paragraph>{t('benDescOne')}</Paragraph>
        </SectionText>
        <SectionText>
          <IconIMG src={ElementIMG} />
          <Paragraph>{t('benDescTwo')}</Paragraph>
        </SectionText>

        <SectionText>
          <IconIMG src={PlusIMG} />
          <Paragraph>{t('benDescThree')}</Paragraph>
        </SectionText>
      </Section>
    </Wrapper>
  );
};

export default Benefits;
