import styled from 'styled-components';
import { device } from '~/theme/breakpoints';
import { getTheme, ifStyle, pxToRem } from '~/utils';
import Typography from '../Typography';

// Colors
const primaryDark = getTheme('primary.dark');
const primaryLight = getTheme('primary.light');

// Spacing
const sectionSpacing = getTheme('sectionSpacing');
const minimumSpacing = getTheme('minimumSpacing');

// Ifs

export const Section = styled.div`
  position: fixed;
  z-index: 2;
  width: 100%;
  height: ${pxToRem(90)};
  background-color: ${primaryDark};
  align-items: center;
  justify-content: space-between;
  padding: 0px ${device.isMobile ? '15px' : '0px'};
  align-items: center;
`;

export const AlignItemsMenu = styled.div`
  width: 90%;
  align-items: center;
  margin: 0 auto;
`;

export const MenuIMG = styled.img`
  width: ${pxToRem(50)};
  height: ${pxToRem(30)};
  object-fit: contain;
`;

export const LogoIMG = styled.img`
  height: ${pxToRem(80)};
  object-fit: contain;
`;

export const OptionsMenu = styled.div`
  align-items: center;
  flex-direction: row;
  width: 80%;
  height: 100px;
  justify-content: space-between;
  padding: 0px 3rem;
`;

export const ItemMenu = styled.p`
  font-size: ${device.isMobile ? '1.8rem' : '0.7rem'};
  color: ${primaryLight};
  text-transform: uppercase;
  font-weight: bold;
  width: ${device.isMobile ? '100vw' : '12vw'};
  text-align: center;
  cursor: pointer;
`;

export const ButtonContact = styled.button`
  background: linear-gradient(225.72deg, #00d2ff -43.8%, #006aff 74.69%);
  box-shadow: 0px 34px 30px -20px rgba(0, 0, 0, 0.1);
  width: ${pxToRem(100)};
  height: ${pxToRem(40)};
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  color: ${primaryLight};
  cursor: pointer;
  margin: 0 auto;
`;

export const ButtonPortal = styled.a`
  background: linear-gradient(225.72deg, #00d2ff -43.8%, #006aff 74.69%);
  box-shadow: 0px 34px 30px -20px rgba(0, 0, 0, 0.1);
  width: ${pxToRem(100)};
  height: ${pxToRem(40)};
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  color: ${primaryLight};
  cursor: pointer;
  margin: 0 8px;
  font-size: 13px;
  text-align: center;
  text-decoration: none;

  line-height: ${pxToRem(40)};
  vertical-align: 'center';
`;

export const Touchable = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
`;

export const SocialLogo = styled.img`
  width: ${pxToRem(25)};
  height: ${pxToRem(25)};
  cursor: pointer;
`;

export const SectionSocial = styled.div`
  width: ${device.isMobile ? pxToRem(90) : '10%'};
  min-width: ${pxToRem(20)};
  margin: 0 auto;
  margin-bottom: ${device.isMobile ? '50px' : '0px'};
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-self: center;
`;
