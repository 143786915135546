import styled from 'styled-components';
import bk from '~/theme/breakpoints';
import { pxToRem } from '~/utils';

export const Section = styled.div`
  flex-direction: row;
  max-height: 700px;

  @media ${bk.inMobile} {
    flex-direction: column;
    background-color: #040404;
    max-height: unset;
  }
`;

export const SectionText = styled.div`
  position: absolute;
  width: 100%;
  flex-direction: row;
  left: ${pxToRem(150)};
  margin-top: ${pxToRem(100)};

  @media ${bk.inMobile} {
    left: 0;
    padding-left: ${pxToRem(20)};
    margin-top: ${pxToRem(25)};
  }
`;

export const TextWrapper = styled.div`
  flex-direction: column;
  margin-left: 20px;
`;

export const PinImg = styled.img`
  width: ${pxToRem(30)};
  height: ${pxToRem(40)};

  @media ${bk.inMobile} {
    width: ${pxToRem(15)};
    height: ${pxToRem(20)};
  }
`;

export const Image = styled.img`
  width: 50%;
  object-fit: cover;
  @media ${bk.inMobile} {
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.8rem;
  margin-top: 0px;
  margin-bottom: -10px;
  font-weight: 400;
  color: #ffffff;

  @media ${bk.inMobile} {
    margin-top: 0px;
    font-size: 1rem;
  }
`;

export const Description = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: -70px;

  @media ${bk.inMobile} {
    font-size: 1rem;
    margin-bottom: -45px;
  }
`;

export const SectionRight = styled.div`
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-left: ${pxToRem(70)};
  padding-right: ${pxToRem(10)};

  @media ${bk.inMobile} {
    width: 90%;
    align-self: center;
    padding-left: 0;
    padding: ${pxToRem(30)} 0px;
  }
`;

export const RightTitle = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  color: #656565;

  @media ${bk.inMobile} {
    width: 100%;
    font-size: 1rem;
    color: #fff;
    text-align: center;
  }
`;

export const RightParagraph = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: #444444;

  @media ${bk.inMobile} {
    font-size: 1.1rem;
    color: #fff;
    text-align: center;
  }
`;

export const RightDescription = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  color: #444444;

  @media ${bk.inMobile} {
    font-size: 1rem;
    color: #fff;
    text-align: center;
  }
`;
