/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import If from '../If';
import {
  Description,
  Footer,
  Paragraph,
  Section,
  SectionText,
  Title,
} from './styles';

type Props = {};

function useOnScreen(ref: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting),
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

const Results: FC<Props> = () => {
  const { t } = useTranslation('translation');

  const ref = useRef(null);

  const isVisible = useOnScreen(ref);

  return (
    <>
      <Section ref={ref}>
        <SectionText>
          <Title>
            +
            <If condition={isVisible}>
              <CountUp end={80} />
            </If>
            &nbsp; {t('polyTitle')}
          </Title>
          <Paragraph>{t('polyDesc')}</Paragraph>
        </SectionText>

        <SectionText>
          <Title>
            +
            <If condition={isVisible}>
              <CountUp end={25} />
            </If>
            &nbsp; {t('polyTitle')}
          </Title>
          <Paragraph>{t('polyDescTwo')}</Paragraph>
        </SectionText>

        <SectionText>
          <Title>
            <If condition={isVisible}>
              <CountUp start={0} end={99.8} decimals={1} />
            </If>
            %
          </Title>
          <Paragraph>{t('polyDescThree')}</Paragraph>
        </SectionText>
      </Section>

      <Footer>
        <Description>{t('polyDescFinish')}</Description>
        <Description>{t('polyDescFinish2')}</Description>
      </Footer>
    </>
  );
};

export default Results;
