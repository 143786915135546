import styled from 'styled-components';
import bk from '~/theme/breakpoints';
import { pxToRem } from '~/utils';
import Typography from '../Typography';

export const Section = styled.div`
  flex-direction: row;
  background-color: #ededed;
  min-height: ${pxToRem(200)};
  align-items: center;

  @media ${bk.inMobile} {
    flex-direction: column;
  }
`;

export const SectionText = styled.div`
  width: 33%;
  flex-direction: column;
  text-align: center;
  min-height: ${pxToRem(250)};
  margin: ${pxToRem(30)} 0px;

  @media ${bk.inMobile} {
    margin-bottom: ${pxToRem(20)};
    width: 100%;
  }
`;

export const Title = styled.h3`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: -10px;
  color: #0090ff;
`;

export const Paragraph = styled.p`
  font-size: 1.4rem;
  margin: 0 auto;
  width: 50%;
  color: #040404;
  font-weight: 300;

  @media ${bk.inMobile} {
    width: 80%;
  }
`;

export const Footer = styled.div`
  background-color: #0090ff;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Description = styled.h1`
  text-align: center;
  margin: 0 auto;
  width: 90%;
  padding: ${pxToRem(40)} ${pxToRem(30)};
  color: #fff;
  font-weight: 400;
  font-size: 2rem;

  @media ${bk.inMobile} {
    font-size: 1rem;
    width: 100%;
  }
`;
