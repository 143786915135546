import styled from 'styled-components';
import bk from '~/theme/breakpoints';
import { pxToRem } from '~/utils';

export const Section = styled.div`
  width: 100%;
  flex-direction: column;
  min-height: ${pxToRem(200)};
  background-color: #fff;
  padding: ${pxToRem(20)} 0px;
`;

export const Title = styled.p`
  font-size: 2.5vw;
  margin-bottom: 10px;
  font-weight: bold;
  color: #a5a5a5;
  text-align: center;

  @media ${bk.inMobile} {
    margin-top: 0px;
    font-size: 2rem;
    text-align: center;
  }
`;

export const SectionImages = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: ${pxToRem(40)};
  padding-bottom: ${pxToRem(50)};

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${bk.inMobile} {
    flex-direction: column;
  }
`;

export const Images = styled.img`
  width: ${pxToRem(200)};
`;

export const ImageTextSection = styled.div`
  width: 30%;
  flex-direction: column;
  align-items: center;

  @media ${bk.inMobile} {
    margin-bottom: ${pxToRem(20)};
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.2vw;
  margin-bottom: 10px;
  font-weight: 400;
  color: #444444;
  text-align: center;

  @media ${bk.inMobile} {
    font-size: 0.9rem;
    text-align: center;
  }
`;

export const Description = styled.p`
  font-size: 1.2vw;
  margin-bottom: 10px;
  font-weight: bold;
  color: #444444;
  text-align: center;

  @media ${bk.inMobile} {
    font-size: 5vw;
  }
`;

export const EndSection = styled.div`
  width: 100%;
  height: ${pxToRem(250)};
  background-color: #000;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media ${bk.inMobile} {
    height: ${pxToRem(200)};
  }
`;

export const SectionImageFooter = styled.div`
  flex-direction: row;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  justify-content: space-between;

  @media ${bk.inMobile} {
    flex-direction: column;
  }
`;

export const LogoImg = styled.img`
  width: ${pxToRem(200)};

  @media ${bk.inMobile} {
    width: 90%;
  }
`;

export const WrapperSocial = styled.div`
  flex-direction: row;
  align-items: center;
`;

export const SocialImg = styled.img`
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  margin-left: ${pxToRem(10)};
  cursor: pointer;
`;

export const EmailText = styled.p`
  color: #fafafa;
  -webkit-user-select: text;
`;

export const CopyText = styled.p`
  color: #fafafa;

  @media ${bk.inMobile} {
    text-align: center;
    font-size: 2.7vw;
  }
`;

export const SectionPolicy = styled.div``;
