import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BandIMG,
  ForbesIMG,
  InstFooter,
  LinkFooter,
  LogoFooter,
  RPCIMG,
} from '~/assets/img';
import If from '../If';
import {
  CopyText,
  Description,
  EmailText,
  EndSection,
  ImageTextSection,
  Images,
  LogoImg,
  Paragraph,
  Section,
  SectionImageFooter,
  SectionImages,
  SectionPolicy,
  SocialImg,
  Title,
  WrapperSocial,
} from './styles';

type Props = {
  showNews?: boolean;
};

const Footer: FC<Props> = ({ showNews = true }) => {
  const { t } = useTranslation('translation');

  const onInsta = () => {
    window.open('https://www.instagram.com/transceptar', '_blank');
  };

  const onLinkedIn = () => {
    window.open(
      'https://www.linkedin.com/in/transceptar-s-a-58b48924a',
      '_blank',
    );
  };

  return (
    <Section id="sectionInTheNews">
      <If condition={showNews}>
        <Title>{t('InTheNews')}</Title>
        <SectionImages>
          <ImageTextSection>
            <Paragraph>{t('newsOneDate')}</Paragraph>
            <Images src={ForbesIMG} />
            <Description>{t('newsOneDesc')}</Description>
          </ImageTextSection>

          <ImageTextSection>
            <Paragraph>{t('newsTwoDate')}</Paragraph>
            <Images src={BandIMG} />
            <Description>{t('newsTwoDesc')}</Description>
          </ImageTextSection>

          <ImageTextSection>
            <Paragraph>{t('newsThreeDate')}</Paragraph>

            <Images src={RPCIMG} />
            <Description>{t('newsThreeDesc')}</Description>
          </ImageTextSection>
        </SectionImages>
      </If>
      <EndSection>
        <SectionImageFooter>
          <LogoImg src={LogoFooter} />
          <WrapperSocial>
            <EmailText>info@transceptar.com</EmailText>
            {/* <SocialImg src={InstFooter} onClick={onInsta} />
            <SocialImg src={LinkFooter} onClick={onLinkedIn} /> */}
          </WrapperSocial>
        </SectionImageFooter>

        <SectionPolicy />
        <CopyText>{t('copyText')}</CopyText>
      </EndSection>
    </Section>
  );
};

export default Footer;
