import styled from 'styled-components';
import bk from '~/theme/breakpoints';
import { pxToRem } from '~/utils';

export const Section = styled.div`
  height: 850px;
  @media ${bk.inMobile} {
    background-color: #000000;
    height: unset;
  }
`;

export const ImageBanner = styled.img`
  width: 100%;
  object-fit: cover;
  display: none;

  @media ${bk.inDesktop} {
    display: block;
  }
`;

export const SectionText = styled.div`
  width: 100%;
  position: absolute;
  flex-direction: row;
  left: ${pxToRem(130)};
  margin-top: ${pxToRem(100)};

  @media ${bk.inMobile} {
    position: initial;
    background-color: #000000;
    min-height: auto;
    left: 0;
    margin-top: 0;
  }
`;

export const TextWrapper = styled.div`
  width: 50%;
  flex-direction: column;
  margin-left: 20px;

  @media ${bk.inMobile} {
    width: 90%;
    margin: 0 auto;
    padding: 15px 0;
  }
`;

export const Title = styled.p`
  font-size: 1.7vw;
  margin-bottom: 10px;
  font-weight: 500;
  color: #d3d3d3;

  @media ${bk.inMobile} {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffffff;

  @media ${bk.inMobile} {
    margin-top: 0px;
    font-size: 0.9rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: #ffffff;

  @media ${bk.inMobile} {
    font-size: 0.9rem;
  }
`;
