export default {
  // header
  about: 'About',
  technology: 'Technology',
  solutions: 'Solutions',
  InTheNews: 'In the news',
  portalUs: 'Portal Access',
  contactUs: 'Contact Us',

  // banner
  techHealth: 'Technologies for health',

  driveDesc: 'We drive genetics resilience through',
  driveOpt1: 'Precision Drugs',
  driveOpt2: 'Personalized Supplements',
  driveOpt3: 'Personalized Diet',
  driveOpt4: 'Personalized Physical Activity',
  driveOpt5: 'Lifestyle Guidances leading to Longevity',

  descOne: 'Bringing the',
  descTwo: 'future to you',

  submitBanner: 'Submit a request',

  // technologies
  kfar: 'We are a technology company that focuses',
  kfarDescSubOne: 'on harnessing the power of Artificial Intelligence',
  kfarDescOne:
    'to provide the most advanced solutions for health and precision',
  kfarDescTwo:
    'medicine. Our team of experts is committed to delivering the most',
  kfarDescThree:
    'advanced, AI-driven solutions that generate reliable and actionable',
  kfarDescFour:
    'insights. With our cutting-edge AI solutions, you will stay ahead',
  kfarDescFive: 'in the ever-evolving world.',

  transTech: 'Transceptar Technologies',
  transTechDescOne:
    'Transceptar is the industry leader in health and precision medicine technology, making use of artificial intelligence (AI) to help provide unparalleled insight into medical diagnostics and treatment options. One of the most impressive AI services that Transceptar provides is its world-leading genetic testing company, which uses AI technologies to generate the most accurate and precise DNA test reports since 2011.',
  transTechDescTwo:
    'Transceptar’s AI services offer numerous advantages to health and medical professionals, patients, and researchers alike. Physicians can use the precise genetic test reports to make better informed decisions about their patients, customizing treatments to their individual genetic makeup. For researchers and medical students, Transceptar’s deep insights into DNA sequencing allow for more precise development of treatments and therapies. And for patients, reliable and precise genetic reports can help them make more informed lifestyle choices and lifestyle-related management of their health.',
  transTechDescThree:
    'Leading the way in health and precision medicine technology, Transceptar’s AI is revolutionizing the field with its benefits. By delivering accurate and precise reports from a comprehensive DNA test, Transceptar is paving the way for smarter medical decisions and outcomes.',

  // Results
  polyTitle: 'million',
  polyDesc: 'Genetic variants analyzed',
  polyDescTwo: 'Scientific publications monitored in real time',
  polyDescThree: 'Accuracy in results with our AI-based system',
  polyDescFinish:
    'Our cutting-edge technology delivers the most up-to-date and reliable results, enabling doctors to take the right interventions for each patient individually. With our comprehensive genetic analysis, we empower medical professionals to make informed decisions that can change lives.',
  polyDescFinish2:
    'Welcome to the World of Personalized Medicine with Transceptar.',

  // Tech health
  techHealthTitle: 'Health technology',
  techHealthDescOne:
    "Genomic analysis, has the potential to greatly impact people's lives in a positive manner. It allows for a better understanding of our genetic makeup and the identification of variations that may be associated with specific health conditions. By unlocking the secrets of our genetic code, we can revolutionize healthcare and improve overall wellbeing.",
  techHealthDescTwo:
    "Precision medicine is an approach that tailors medical treatment to an individual's unique genetic, environmental, and lifestyle factors. DNA analysis plays a crucial role in precision medicine, as it enables healthcare providers to identify specific genetic variants that may contribute to a person's susceptibility to certain diseases or their response to specific medications. This information allows medical professionals to design targeted treatment plans, reducing the risks of side effects and increasing the chances of successful outcomes. Ultimately, precision medicine results in more personalized and effective healthcare. In addition to improving disease treatment and prevention, DNA analysis also has significant implications for longevity. By identifying genetic factors that contribute to aging and age-related diseases, scientists can develop targeted interventions to promote healthy aging and extend life expectancy. DNA analysis may lead to breakthroughs in understanding the biological processes that drive aging, opening the door for new therapies that could delay or even reverse some of its effects.",

  // Beneficies
  benDescOne: 'High precision algorithms for genetics and health',
  benDescTwo: 'Sequencing Strand Inversion Algorithm with ethnicity percentage',
  benDescThree: 'Healthcare case studies provided by health professionals',
  benDescFinish:
    'A.I for Monitoring and updating the database. It absorbs and organizes monthly + 2.5 million scientific publications worldwide.',

  //  Solutions
  solutionsTitle: 'Premium Solutions',
  solutionsDescOne:
    'An actionable diagnostics system plays a crucial role in medical treatment, particularly following the receipt of a DNA analysis report for diagnostic or preventative purposes. By harnessing the power of our advanced artificial intelligence engine, our genetics platform thoroughly examines over 3 billion base pairs for each person. ',
  solutionsDescTwo:
    'This comprehensive analysis allows us to provide customized reports that offer not only actionable diagnostic recommendations but also tailored lifestyle advice designed to promote long-term health and well-being. By integrating these insights into individualized treatment plans, patients can benefit from targeted therapies and preventative measures that cater specifically to their unique genetic makeup.',
  solutionsFinish:
    'Get access to customized dashboards according to every need with Transceptar Panels.',

  // in the news

  newsOneDate: '26 March 2020',
  newsOneDesc:
    'Startup develops test that identifies genetic vulnerability to Covid-19',

  newsTwoDate: '15 July 2020',
  newsTwoDesc:
    'Startup will work with the University of Washington to help combat covid-19',

  newsThreeDate: '29 January 2021',
  newsThreeDesc:
    'New unicorns? Meet 16 startups from Paraná to keep an eye on 2021',

  copyText: 'Copyright 2023 Transceptar Technologies. All Rights Reserved.',
  sendWithSuccess: 'Send with Success!',

  // Form
  name: 'NAME*',
  lastName: 'LAST NAME*',
  phone: 'PHONE',
  country: 'COUNTRY',
  email: 'EMAIL*',
  company: 'COMPANY',
  message: 'MESSAGE*',
  submit: 'Submit',
};
